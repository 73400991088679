<template>
	<div class="order_wrap">
		<v-container class="order_home px-6">
			<div class="txt_box mt-8">
				<p>
					<span class="impact">다음 단계 </span>
				</p>
				<div class="txt_number">
					<dl>
						<dt>1.</dt>
						<dd>주문 페이지로 돌아가신다음</dd>
					</dl>
					<dl>
						<dt>2.</dt>
						<dd>하단의 '전자계약 서명완료 확인' 버튼을 눌러주세요</dd>
					</dl>
					<dl>
						<dt>3.</dt>
						<dd>이후 주문 마무리 단계를 진행해주시면 됩니다.</dd>
					</dl>
				</div>
				<div class="wrap_sample">
					<img class="my-6" src="/img/signatureComplate_infor.gif" alt="" />
				</div>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	mounted() {
		this.$nextTick(() => {
			document.title = '코웨이닷컴'
		})
	},
	methods: {}
}
</script>

<style scoped lang="scss"></style>
